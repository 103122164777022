import { action, observable, ObservableMap, reaction, runInAction } from "mobx";

import { ServerError } from "@bps/http-client";
import { DateTime } from "@bps/utils";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import { Entity } from "@libs/api/hub/Entity.ts";
import { EntityEventData } from "@libs/api/hub/EntityEventData.ts";
import { EventAction } from "@libs/api/hub/EventAction.ts";
import { IHubGateway } from "@libs/api/hub/HubGateway.ts";
import {
  deepEqualResolver,
  sharePendingPromise
} from "@libs/decorators/sharePendingPromise.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  AddUserAction,
  AdvancedFilter,
  CreatePdfArgs,
  DocumentEntityType,
  FormAddInstructionsTask,
  FormAddUserActionType,
  FormUpdateUserAction,
  InboxDocumentDto,
  InboxDocumentSearchArgs,
  MoveToClinicalRecordArgsDto,
  PdfErrors,
  ReceptionTaskDto,
  TaskSearchArgsDto,
  UpdateInboxDocumentDto,
  UpdateSubmitAndStoreArgsDto,
  UploadBatchArgsDto,
  UserActionDto,
  UserTaskDto
} from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { IInboxDocumentsGateway } from "@libs/gateways/inbox/InboxGateway.interface.ts";
import {
  isImage,
  isPdf,
  isPreviewAndPrintSupported
} from "@libs/utils/file.utils.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { getOrThrow } from "@libs/utils/utils.ts";
import type { IRootStore } from "@shared-types/root/root-store.interface.ts";
import type { Store } from "@stores/types/store.type.ts";
import { mergeModel } from "@stores/utils/store.utils.ts";

import { InboxDocumentRef } from "./InboxDocumentRef.ts";
import { InboxDocumentsUi } from "./InboxDocumentsUi.ts";
import { InboxDocument } from "./models/InboxDocument.ts";
import { PendingUpload } from "./models/PendingUpload.ts";
import { UserTask } from "./models/UserTask.ts";
import { GetInboxDocumentArgs } from "./types/get-inbox-document-args.interface.ts";

export class InboxDocumentsStore
  implements Store<InboxDocumentsStore, InboxDocumentRef>
{
  constructor(
    private gateway: IInboxDocumentsGateway,
    public hub: IHubGateway
  ) {
    this.ref = new InboxDocumentRef(this.gateway);
  }

  afterAttachRoot() {
    // when logged in set user documents count
    reaction(
      () => this.root.core.isLoggedIn,
      () =>
        this.root.core.hasPermissions([
          Permission.DocWorkflowRead,
          Permission.PreRelease
        ]) && this.setUserDocumentsCount(false)
    );

    // subscribe any changes in documents count
    this.hub.onEntityEvent(
      Entity.DocumentWorkflowInboxStateAddItem,
      this.onInboxStateAddNewItemEvent
    );

    this.hub.onEntityEvent(
      Entity.DocumentWorkflowInboxDocumentUpdate,
      this.onInboxDocumentUpdated
    );

    this.hub.onEntityEvent(Entity.UserTask, this.onUserTaskUpdated);
  }

  root: IRootStore;
  ref: InboxDocumentRef;
  ui = new InboxDocumentsUi(this);

  inboxDocumentsMap = observable.map<string, InboxDocument>();
  uploadSessionDocsMap = observable.map<string, InboxDocument>();
  pendingUploadMap = observable.map<string, PendingUpload>();
  userTasksMap = observable.map<string, UserTask>();

  private get notification() {
    return this.root.notification;
  }

  private onInboxStateAddNewItemEvent = async (
    evt: EntityEventData<string>
  ) => {
    const eventUserId = evt.key;
    eventUserId === this.root.core.userId && this.setUserDocumentsCount(true);
  };

  setUserDocumentsCount = async (notifyWhenError: boolean) => {
    const count = await this.getUserInboxState(notifyWhenError);
    if (count) {
      runInAction(() => {
        this.ui.documentsCount = count.newDocumentCount;
      });
    }
  };

  @action
  private mergeInboxDocument = (
    dto: InboxDocumentDto,
    mergeToMap: ObservableMap<string, InboxDocument>
  ) => {
    return mergeModel({
      dto,
      getNewModel: () => new InboxDocument(this.root, dto),
      map: mergeToMap
    });
  };

  @action
  private mergeUserTask = (
    dto: UserTaskDto,
    mergeToMap: ObservableMap<string, UserTask>
  ) => {
    return mergeModel({
      dto,
      getNewModel: () => new UserTask(this.root, dto),
      map: mergeToMap
    });
  };

  private onUserTaskUpdated = async (event: EntityEventData) => {
    if (event.id != null && event.action === EventAction.Update) {
      this.ui.setUserTaskRefreshKey({ eTag: event.etag });
    }
  };

  private onInboxDocumentUpdated = async (event: EntityEventData) => {
    if (event.id != null) {
      // create and commit actions excluded because newly uploaded documents will never appear in the inbox list
      //  - which is the only place documentRefreshKey is used at the moment
      if (
        event.action === EventAction.Delete ||
        event.action === EventAction.Update
      ) {
        this.ui.setDocumentRefreshKey(
          `${event.action}.${event.etag || event.id}`
        );
      }
    }
  };

  @sharePendingPromise()
  async getSaSUri() {
    return await this.gateway.getSaSUri();
  }

  @action
  async loadUnassignedDocuments(args: PagingOptions) {
    return this.getInboxDocuments({
      ...args,
      advancedFilter: AdvancedFilter.InboxUploads
    });
  }

  @sharePendingPromise()
  async getUserInboxState(notifyWhenError: boolean) {
    try {
      return await this.gateway.getUserInboxState();
    } catch (e) {
      if (notifyWhenError) this.notification.error(e);
    }
    return 0;
  }

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  async fetchInboxDocuments(
    args: InboxDocumentSearchArgs
  ): Promise<QueryResult<InboxDocument>> {
    const { results, ...rest } = await this.gateway.getInboxDocuments(args);
    await this.fetchUserAndContactInboxDocument(results);
    const mergeResults = results.map(dto =>
      this.mergeInboxDocument(dto, this.inboxDocumentsMap)
    );
    return {
      results: mergeResults,
      ...rest
    };
  }

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  async fetchCurrentUserInboxDocuments(
    args: PagingOptions
  ): Promise<QueryResult<InboxDocument>> {
    runInAction(() => {
      this.inboxDocumentsMap.clear();
    });

    const { results, ...rest } = await this.gateway.getUserInboxDocuments(args);
    await this.fetchUserAndContactInboxDocument(results);
    return {
      results: results.map(dto =>
        this.mergeInboxDocument(dto, this.inboxDocumentsMap)
      ),
      ...rest
    };
  }

  private async fetchUserAndContactInboxDocument(
    documentDto: InboxDocumentDto[]
  ) {
    const userIds = documentDto.map(d => d.assignedToUserId);

    const contactIds = documentDto
      .map(d => d.fromContactId)
      .concat(documentDto.map(d => d.patientId));

    await Promise.all([
      await this.root.core.getUsersByIds(userIds.filter(id => id)),
      await this.root.practice.getContactsById(contactIds)
    ]);
  }

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  async getInboxDocuments(
    args: InboxDocumentSearchArgs
  ): Promise<QueryResult<InboxDocument>> {
    runInAction(() => {
      this.inboxDocumentsMap.clear();
    });

    const response = await this.fetchInboxDocuments(args);
    return response;
  }

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  async getCurrentUserInboxDocuments(
    args: PagingOptions
  ): Promise<QueryResult<InboxDocument>> {
    runInAction(() => {
      this.inboxDocumentsMap.clear();
    });

    const response = await this.fetchCurrentUserInboxDocuments(args);
    return response;
  }

  async createPdf(args: CreatePdfArgs, docExtension: string): Promise<string> {
    try {
      if (isPreviewAndPrintSupported(docExtension)) {
        return await this.gateway.createPdf(args);
      }

      return PdfErrors.CANT_GENERATE_PDF;
    } catch (error) {
      if (error instanceof ServerError) {
        if (error.status === 504) {
          return PdfErrors.PDF_TIMEOUT;
        }
      }
      return PdfErrors.ERROR_WHILE_GENERATING;
    }
  }

  async getUserTasks(
    args: TaskSearchArgsDto & PagingOptions = {}
  ): Promise<QueryResult<UserTask>> {
    const { results, ...rest } = await this.gateway.getUserTasks(args);

    const contactIds = results
      .map(ut => {
        const uacd = ut.userAction?.context;
        return (
          uacd?.storeIn?.inboxDocument?.patientId ??
          uacd?.patientKey?.patientId ??
          ""
        );
      })
      .filter(id => id !== "");
    await this.root.practice.getContactsById(contactIds);

    const tasksPromise = results.map(dto => {
      return this.mergeUserTask(dto, this.userTasksMap);
    });

    await Promise.all(
      tasksPromise.map(async task => {
        await Promise.all([task.loadRelatedUser(), task.loadRelatedPatient()]);
      })
    );

    return { results: tasksPromise, ...rest };
  }

  @sharePendingPromise()
  async getUserTask(id: string, ignoreCache?: boolean) {
    if (!ignoreCache && this.userTasksMap.has(id)) {
      return this.userTasksMap.get(id)!;
    }

    try {
      const taskDto = await this.gateway.getUserTask(id);
      return this.mergeUserTask(taskDto, this.userTasksMap);
    } catch (e) {
      throw e;
    }
  }

  @action
  async updateUserTask(data: UserTaskDto) {
    try {
      const promise = await this.gateway.updateUserTask(data);
      return this.mergeUserTask(promise, this.userTasksMap);
    } catch (e) {
      this.notification.error(e);
      throw e;
    }
  }

  private async fetchInboxDocument(args: GetInboxDocumentArgs) {
    const { id, documentDetailId, mergeToMap } = args;
    const documentDto = await this.gateway.getInboxDocument(
      id,
      documentDetailId
    );

    const inboxModel = this.mergeInboxDocument(documentDto, mergeToMap);

    // Is it image or PDF?
    if (isImage(inboxModel.docExtension!) || isPdf(inboxModel.docExtension!)) {
      inboxModel.previewUri = documentDto.blobSasUri;
      inboxModel.downloadLinkUri = documentDto.blobFileUri;
      return inboxModel;
    }

    const updatedSasUri = await this.createPdf(
      {
        entityType: DocumentEntityType.DocumentProcessing,
        entityId: id,
        documentId: documentDetailId
      },
      inboxModel.docExtension!
    );

    const docDto = await this.gateway.getInboxDocument(id, documentDetailId);

    const inboxDocModel = this.mergeInboxDocument(docDto, mergeToMap);
    inboxDocModel.previewUri = updatedSasUri;
    return inboxDocModel;
  }

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  async getInboxDocument(
    args: Omit<GetInboxDocumentArgs, "mergeToMap">,
    options?: { ignoreCache?: boolean }
  ) {
    return await this.getInboxDocumentWithMap(
      {
        ...args,
        mergeToMap: this.inboxDocumentsMap
      },
      options
    );
  }

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  async getUploadedInboxDocument(
    args: Omit<GetInboxDocumentArgs, "mergeToMap">,
    options?: { ignoreCache?: boolean }
  ) {
    return await this.getInboxDocumentWithMap(
      {
        ...args,
        mergeToMap: this.uploadSessionDocsMap
      },
      options
    );
  }

  async getInboxDocumentWithMap(
    args: GetInboxDocumentArgs,
    options?: { ignoreCache?: boolean }
  ) {
    let document: InboxDocument;

    const cachedDocument =
      this.inboxDocumentsMap.get(args.id) ||
      this.uploadSessionDocsMap.get(args.id);
    if (cachedDocument && !options?.ignoreCache) {
      document = cachedDocument;
    } else {
      document = await this.fetchInboxDocument(args);
    }

    await Promise.all([
      document.loadRelatedUser(),
      document.loadRelatedContact(),
      document.loadCreatedByUser()
    ]);

    return document;
  }

  async updateAssignInboxDocument(
    data: UpdateInboxDocumentDto,
    options: {
      assignedToUserIdChanged?: boolean;
      removeDocFromMap?: boolean;
    }
  ) {
    const updatedDoc = await this.gateway.updateAssignInboxDocument(data);
    return await this.updateInboxDocument(data, updatedDoc, options);
  }

  async updateAssignUserInboxDocument(
    data: UpdateInboxDocumentDto,
    options: {
      assignedToUserIdChanged?: boolean;
      removeDocFromMap?: boolean;
    }
  ) {
    const updatedDoc = await this.gateway.updateAssignUserInboxDocument(data);
    return await this.updateInboxDocument(data, updatedDoc, options);
  }

  private async updateInboxDocument(
    data: UpdateInboxDocumentDto,
    updatedDoc: InboxDocumentDto,
    options: {
      assignedToUserIdChanged?: boolean;
      removeDocFromMap?: boolean;
    }
  ) {
    if (options.removeDocFromMap && options.assignedToUserIdChanged) {
      runInAction(() => {
        this.uploadSessionDocsMap.delete(data.id);
        this.inboxDocumentsMap.delete(data.id);
      });
    } else {
      this.mergeInboxDocument(updatedDoc, this.inboxDocumentsMap);
    }
  }

  @action
  updateStoreInboxDocument = async (data: UpdateSubmitAndStoreArgsDto) => {
    let doc = this.uploadSessionDocsMap.get(data.inboxDocument.id);
    if (!doc) doc = getOrThrow(this.inboxDocumentsMap, data.inboxDocument.id);

    const inboxDocument = {
      ...data.inboxDocument,
      eTag: doc.eTag,
      changeLog: doc.changeLog
    };

    const updatedDoc = await this.gateway.updateStoreInboxDocument({
      ...data,
      inboxDocument
    });

    runInAction(() => {
      this.uploadSessionDocsMap.delete(data.inboxDocument.id);
    });

    return await this.updateInboxDocument(inboxDocument, updatedDoc, {});
  };

  @action
  async upload(data: UploadBatchArgsDto) {
    await this.gateway.upload(data);
    runInAction(() => {
      data.inboxDocuments.forEach(doc => {
        mergeModel({
          dto: doc,
          getNewModel: () => new PendingUpload(doc),
          map: this.pendingUploadMap
        });
      });
    });
  }

  @action
  async addInboxUserAction(
    data: FormAddUserActionType,
    userTask?: FormAddInstructionsTask,
    toReception?: boolean
  ) {
    const { storeInDestination, userActionType, reportType } = data;
    const doc = this.getDocumentFromMap(data.id);

    const assignedToUserId = doc.assignedToUserId;

    const storeIn: MoveToClinicalRecordArgsDto = {
      inboxDocument: {
        assignedToUserId,
        documentDetailId: doc.documentDetailId,
        documentDate: DateTime.jsDateToISODate(doc.documentDate),
        receivedDate: DateTime.jsDateToISODate(doc.receivedDate),
        correspondenceType: doc.correspondenceType,
        extension: doc.docExtension!,
        patientId: doc.patientId,
        name: doc.name!,
        eTag: doc.eTag,
        changeLog: doc.changeLog,
        fromContactId: doc.fromContactId,
        id: doc.id,
        patientFirstName: doc.patientFirstName,
        patientLastName: doc.patientLastName,
        checkedBy: this.root.core.userId,
        checkedDateTime: DateTime.now().toISO(),
        showOnTimeline: userTask?.showOnTimeline,
        secGroupId: userTask?.secGroupId,
        extraInfo: doc.extraInfo
      },
      storeInDestination,
      reportType
    };

    if (userTask && !toReception) {
      await this.gateway.addUserAction({
        userActionType,
        context: {
          userTask: {
            assignedToUserId,
            dueDateTime: userTask.dueDateTime,
            instructionCode: userTask.instructionCode
          } as UserTaskDto,
          storeIn
        }
      });
      return;
    }

    if (userTask && toReception) {
      await this.gateway.addUserAction({
        userActionType,
        context: {
          userTask: {
            dueDateTime: userTask.dueDateTime,
            instructionCode: userTask.instructionCode
          } as ReceptionTaskDto,
          storeIn
        }
      });
      return;
    }

    await this.gateway.addUserAction({
      userActionType,
      context: {
        storeIn
      }
    });
  }

  @action
  async updateUserAction(data: FormUpdateUserAction): Promise<UserActionDto> {
    const action = await this.gateway.updateUserAction({
      userActionType: data.userActionType,
      context: data.context,
      id: data.id,
      eTag: data.eTag
    });
    return action;
  }

  getDocumentFromMap(id: string) {
    const document = this.inboxDocumentsMap.get(id);
    if (!document) {
      throw Error(`Document ${id} is not found in store`);
    }
    return document;
  }

  async getDocumentFileContents(fileUri?: string) {
    if (!fileUri) {
      return;
    }
    return await this.gateway.getFileContents(fileUri);
  }

  resetUserInboxState() {
    return this.gateway.resetUserInboxState();
  }

  addInboxStateItem(userId: string, inboxDocumentId: string) {
    return this.gateway.addInboxStateItem(userId, inboxDocumentId);
  }

  async deleteInboxDocument(id: string, inboxDocumentId: string) {
    await this.gateway.deleteInboxDocument(id, inboxDocumentId);
    runInAction(() => {
      this.uploadSessionDocsMap.delete(id);
    });
  }

  onDocumentUploadedEvent = async (event: EntityEventData<string>) => {
    try {
      if (event.key.startsWith("staging")) {
        const keys = event.key.split(":");
        if (keys.length === 4) {
          const documentDetailId = keys[2];
          const id = keys[3];
          const pendingUploads = Array.from(this.pendingUploadMap?.values());
          let doc;
          if (pendingUploads.some(u => u.id === id)) {
            doc = await this.getUploadedInboxDocument({
              id,
              documentDetailId
            });

            this.ui.setDocumentRefreshKey(
              `${event.action}.${event.etag || event.id}`
            );
          } else {
            doc = await this.getInboxDocument({
              id,
              documentDetailId
            });
          }

          this.notification.success(
            `File ${doc.name} has been uploaded by ${doc.createdBy?.firstNameWithTitle} successfully`
          );
        }
      }
    } catch (error) {
      this.notification.error(error);
    }
  };

  getPatientDocument = async (
    patientId: string,
    documentId: string
  ): Promise<InboxDocument> => {
    const inboxDocument = this.inboxDocumentsMap.get(documentId);
    if (inboxDocument) return inboxDocument;

    const dto = await this.gateway.getPatientDocument(patientId, documentId);
    const doc = new InboxDocument(this.root, dto);
    runInAction(() => {
      this.inboxDocumentsMap.set(doc.documentDetailId, doc);
    });
    return doc;
  };

  addUserAction = async (data: AddUserAction) => {
    await this.gateway.addUserAction(data);
  };
}
