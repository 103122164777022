import { action, observable, runInAction } from "mobx";

import { DateTime } from "@bps/utils";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import { Entity } from "@libs/api/hub/Entity.ts";
import { EntityEventData } from "@libs/api/hub/EntityEventData.ts";
import { EventAction } from "@libs/api/hub/EventAction.ts";
import { IHubGateway } from "@libs/api/hub/HubGateway.ts";
import { KeyTextValue } from "@libs/api/ref-data/RefDataAccessor.ts";
import { notificationMessages } from "@libs/constants/notification-messages.constants.ts";
import {
  deepEqualResolver,
  sharePendingPromise
} from "@libs/decorators/sharePendingPromise.ts";
import { Country } from "@libs/enums/country.enum.ts";
import {
  AccBenefitCountDto,
  AccTerminologyDto,
  AddClaimAdjustmentDto,
  AddClaimAppointmentDto,
  AddClaimDto,
  AddClaimReviewDto,
  AddHealthCertificateDto,
  AddPurchaseOrderDto,
  BillableOrganisationDto,
  ClaimAdjustmentDto,
  ClaimAppointmentDto,
  ClaimDiagnosisDto,
  ClaimDto,
  ClaimEncounterDto,
  ClaimEpisodeOfCareArgsDto,
  ClaimEpisodeOfCareDto,
  ClaimFormInstanceDTO,
  ClaimReviewDto,
  ClaimsFilter,
  CreateClaimEncounterDto,
  DeleteCheckDto,
  DeleteClaimEpisodeOfCareArgs,
  EmailAcc45SummaryArgs,
  GetAccBenefitCountsDto,
  GetAccTerminologiesArgsDto,
  GetClaimAdjustmentArgsDto,
  GetClaimAppointmentArgsDto,
  GetSchedulesArgsDto,
  HealthCertificateDto,
  PatchClaimAdjustmentDto,
  PatchClaimDto,
  PatchClaimReviewDto,
  PurchaseOrderDto
} from "@libs/gateways/acc/AccGateway.dtos.ts";
import { IAccGateway } from "@libs/gateways/acc/AccGateway.interface.ts";
import { PatientTreatmentPlanDataItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ProviderAccContractTypeDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { patchModel } from "@libs/models/model.utils.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { catchNotFoundError, nullifyAnyUndefined } from "@libs/utils/utils.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { filterAvailableAccProviderTypes } from "@stores/acc/utils/acc.utils.ts";
import { Store } from "@stores/types/store.type.ts";
import { mergeModel } from "@stores/utils/store.utils.ts";

import { AccRef } from "./AccRef.ts";
import { AccUi } from "./AccUi.ts";
import { ClaimAdjustment } from "./models/ClaimAdjustment.ts";
import { ClaimReview } from "./models/ClaimReview.ts";
import { PurchaseOrder } from "./models/PurchaseOrder.ts";
import { Schedule } from "./models/Schedule.ts";

interface ClaimTemplates {
  summaryForm: string | undefined;
  patientForm: string | undefined;
}

export class AccStore implements Store<AccStore, AccRef> {
  constructor(
    private gateway: IAccGateway,
    public hub: IHubGateway
  ) {
    this.ref = new AccRef(this.gateway);
  }

  ref: AccRef;
  ui = new AccUi(this);
  root: IRootStore;

  afterAttachRoot() {
    this.hub.onEntityEvent(Entity.AccClaim, this.onClaimEvent);
    this.hub.onEntityEvent(
      Entity.AccClaimAppointment,
      this.onClaimAppointmentEvent
    );
    this.hub.onEntityEvent(
      Entity.AccClaimAdjustment,
      this.onClaimAdjustmentEvent
    );
    this.hub.onEntityEvent(Entity.AccPurchaseOrder, this.onPurchaseOrderEvent);
    this.hub.onEntityEvent(Entity.ClinicalDocument, this.onDocumentEvent);
  }

  private get notification() {
    return this.root.notification;
  }

  public accTerminologyMap = observable.map<string, AccTerminologyDto>();
  public claimsMap = observable.map<string, Claim>();
  public claimAppointmentMap = observable.map<string, ClaimAppointmentDto>();

  public purchaseOrderMap = observable.map<string, PurchaseOrder>();

  public templates = observable.object<ClaimTemplates>({
    summaryForm: undefined,
    patientForm: undefined
  });

  documentRequestStagingIds: string[] = [];

  private onClaimAdjustmentEvent = async (message: EntityEventData) => {
    if (message.action === EventAction.Update) {
      const claimAdjustment = await this.getClaimAdjustment(message.id);
      await this.updateClaimsMapWithAdjustment(claimAdjustment.dto);
    }
    if (message.action === EventAction.Delete) {
      const [claimAdjustmentId, claimId] = message.key as string[];
      const claim = this.claimsMap.get(claimId);
      claim?.deleteClaimAdjustmentFromMap(claimAdjustmentId);
    }
  };

  private onClaimEvent = async (message: EntityEventData) => {
    if (message.action === EventAction.Delete) {
      await this.onClaimDeleted(message);
    } else if (
      message.action === EventAction.Update ||
      message.action === EventAction.StatusUpdate
    ) {
      await this.onClaimUpdated(message);
    }
  };

  private onDocumentEvent = async (message: EntityEventData) => {
    this.onDocumentRendered(message);
  };

  private onPurchaseOrderEvent = async (event: EntityEventData) => {
    if (event.action === EventAction.Delete) {
      await this.onPurchaseOrderDeleted(event);
    } else if (
      event.action === EventAction.Create ||
      event.action === EventAction.Update
    ) {
      await this.onPurchaseOrderUpdated(event);
    }
  };

  private onPurchaseOrderUpdated = async (event: EntityEventData) => {
    try {
      if (
        event.id != null &&
        (event.action === EventAction.Create ||
          event.action === EventAction.Update)
      ) {
        const purchaseOrder = this.purchaseOrderMap.get(event.id);
        if (
          !purchaseOrder ||
          (purchaseOrder && purchaseOrder.eTag !== event.etag)
        ) {
          this.getPurchaseOrder(event.id, { ignoreCache: true });
        }
        runInAction(() => {
          this.ui.recentlyUpdatedPurchaseOrderKey = event.etag;
        });
      }
    } catch (error) {
      this.notification.error(error);
    }
  };

  private onPurchaseOrderDeleted = async (event: EntityEventData) => {
    if (this.purchaseOrderMap.has(event.id)) {
      runInAction(() => {
        this.purchaseOrderMap.delete(event.id);
        this.ui.recentlyUpdatedPurchaseOrderKey = event.etag + event.id;
      });
    }
  };

  private onDocumentRendered = async (event: EntityEventData) => {
    if (event.action === EventAction.Create) {
      const values = event.key.split(":");
      const stagingId = values[1];
      const documentId = values[2];
      const patientId = values[3];
      const exists = this.documentRequestStagingIds.some(
        id => id === stagingId
      );
      if (exists) {
        await this.previewDocument(documentId, patientId);
        this.documentRequestStagingIds = this.documentRequestStagingIds.filter(
          id => id !== stagingId
        );
      }
    }
  };

  private previewDocument = async (documentId: string, patientId: string) => {
    const document = await this.root.correspondence.getDocumentUrl({
      documentId,
      patientId
    });

    this.ui.setClaimPreviewDocumentData({
      show: true,
      documentId,
      patientId,
      url: document.url
    });
  };

  public generateDocumentById = async (claimId: string) => {
    try {
      await this.gateway.renderClaimForm(claimId);
      this.notification.warn(notificationMessages.emailIsBeingGenerated);
    } catch (e) {
      this.notification.error(e);
    }
  };

  public renderClaimForm = async (claimId: string) => {
    try {
      const staging = await this.gateway.renderClaimForm(claimId);
      this.documentRequestStagingIds.push(staging.fileStagingId);
      this.notification.warn(notificationMessages.formIsBeingPrinted);
    } catch (e) {
      this.notification.error(e);
    }
  };

  public emailAcc45Summary = async (args: EmailAcc45SummaryArgs) => {
    try {
      await this.gateway.emailAcc45Summary(args);
      this.notification.success(notificationMessages.emailIsBeingSent);
    } catch (e) {
      this.notification.error(e);
    }
  };

  private onClaimAppointmentEvent = async (message: EntityEventData) => {
    if (
      message.key &&
      (message.action === EventAction.Update ||
        message.action === EventAction.Create)
    ) {
      const claimAppointment = await this.getClaimAppointmentDto(message.key);

      if (claimAppointment.calendarEventId) {
        await Promise.all([
          this.root.booking.getCalendarEvent(claimAppointment.calendarEventId),
          this.getClaim(claimAppointment.claimId)
        ]);
      }
    }
  };

  private onClaimDeleted = async (event: EntityEventData) => {
    if (this.claimsMap.has(event.id)) {
      runInAction(() => {
        this.claimsMap.delete(event.id);
        this.ui.recentlyUpdatedClaimKey = event.etag + event.id;
      });
    }
  };

  private onClaimUpdated = async (event: EntityEventData) => {
    const claim = this.claimsMap.get(event.id);
    if (claim && claim.eTag !== event.etag) {
      runInAction(() => {
        this.getClaim(event.id, { ignoreCache: true });
        this.ui.recentlyUpdatedClaimKey = event.etag + event.id;
      });
    }
  };

  @action
  private mergeClaim = (dto: ClaimDto) => {
    return mergeModel({
      dto,
      getNewModel: () => new Claim(this.root, dto),
      map: this.claimsMap
    });
  };

  public async addHealthCertificate(
    healthCertificateDto: AddHealthCertificateDto
  ): Promise<HealthCertificateDto> {
    return this.gateway.addHealthCertificate(healthCertificateDto);
  }

  public async updateHealthCertificate(
    healthCertificateDto: AddHealthCertificateDto
  ): Promise<HealthCertificateDto> {
    return this.gateway.updateHealthCertificate(healthCertificateDto);
  }

  public async getHealthCertificate(): Promise<
    HealthCertificateDto | undefined
  > {
    return this.gateway.getHealthCertificate().catch(catchNotFoundError);
  }

  public async deleteHealthCertificate(id: string): Promise<void> {
    await this.gateway.deleteHealthCertificate(id);
  }

  public async queueClaimStatus(claimId: string): Promise<void> {
    await this.gateway.queueClaimStatus(claimId);
  }

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  async getAccBenefitCounts(
    request: Partial<GetAccBenefitCountsDto> = {}
  ): Promise<AccBenefitCountDto[]> {
    try {
      const args = {
        ...request,
        effectiveDate: request.effectiveDate || DateTime.now().toISODate()
      };

      return this.gateway.getAccBenefitCountsByArgs(args);
    } catch (e) {
      this.notification.error(e);
    }
    return [];
  }

  @sharePendingPromise()
  public async getClaim(
    id: string,
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ): Promise<Claim> {
    if (!options.ignoreCache) {
      const claimCached = this.claimsMap.get(id);
      if (claimCached) {
        return claimCached;
      }
    }

    const claimDto = await this.gateway.getClaim(id);
    const claim = this.mergeClaim(claimDto);
    claim.populateClaimAdjustmentMap();
    return claim;
  }

  public async addClaim(claim: AddClaimDto): Promise<Claim> {
    const claimDto = await this.gateway.addClaim(claim);
    return this.mergeClaim(claimDto);
  }

  public async updateClaim(claimDto: ClaimDto): Promise<Claim> {
    const claim = await this.gateway.updateClaim(claimDto);
    return this.mergeClaim(claim);
  }

  public async patchClaim(
    claimDto: Omit<PatchClaimDto, "eTag">
  ): Promise<Claim> {
    return await patchModel(claimDto, req => this.gateway.patchClaim(req), {
      modelMap: this.claimsMap
    });
  }

  public async deleteBulkClaims(claimIds: string[]): Promise<void> {
    await this.gateway.deleteClaims(claimIds);
  }

  public async lodgeBulkAccClaims(claimIds: string[]): Promise<void> {
    try {
      await this.gateway.addLodgeAccClaims(claimIds);
      this.notification.success(notificationMessages.claimsLodged);
    } catch (e) {
      this.notification.error(e);
    }
  }

  public async submitAccInvoices(invoiceIds: string[]): Promise<void> {
    await this.gateway.submitAccInvoices(invoiceIds);
  }

  public async addLodgeAccClaim(claimId: string): Promise<void> {
    await this.gateway.addLodgeAccClaims([claimId]);
  }

  public async updateClaimAppointment(
    request: ClaimAppointmentDto
  ): Promise<ClaimAppointmentDto> {
    return await this.gateway.updateClaimAppointment(request);
  }

  public async getClaimAppointmentDtos(
    request: GetClaimAppointmentArgsDto
  ): Promise<ClaimAppointmentDto[]> {
    return this.gateway.getClaimAppointmentDtos(request).then(results =>
      results.map(dto => {
        runInAction(() => {
          this.claimAppointmentMap.set(dto.id, dto);
        });
        return dto;
      })
    );
  }

  public async getClaimAppointmentDto(
    id: string
  ): Promise<ClaimAppointmentDto> {
    return await this.gateway.getClaimAppointmentDto(id);
  }

  public async deleteClaimAppointmentDto(id: string): Promise<void> {
    await this.gateway.deleteClaimAppointmentDto(id);
  }

  public async addClaimAppointment(
    request: AddClaimAppointmentDto
  ): Promise<ClaimAppointmentDto> {
    return await this.gateway.addClaimAppointment(request);
  }

  public async deleteClaimAppointmentDtoByCalendarEventId(
    id: string
  ): Promise<void> {
    const claimAppointments = await this.root.acc.getClaimAppointmentDtos({
      calendarEventId: id
    });

    runInAction(() => {
      claimAppointments.map(dto => this.claimAppointmentMap.delete(dto.id));
    });

    if (claimAppointments[0]) {
      await this.gateway.deleteClaimAppointmentDto(claimAppointments[0].id);
    }
  }

  @sharePendingPromise()
  public async getClaimAdjustment(id: string): Promise<ClaimAdjustment> {
    const claimAdjustmentDto = await this.gateway.getClaimAdjustment(id);

    return new ClaimAdjustment(this.root, claimAdjustmentDto);
  }

  public async getClaimAdjustmentDiagnosisAdditions(claimId: string) {
    const claim = await this.getClaim(claimId);
    const claimAdjustments = claim.claimAdjustments;

    const diagnosis: ClaimDiagnosisDto[] = [];

    claimAdjustments.forEach(x => {
      if (x.hasDiagnosisAddition && x.diagnosisAdditions) {
        diagnosis.push(...x.diagnosisAdditions);
      }
    });
    return diagnosis;
  }

  public async getClaimAdjustments(
    request: GetClaimAdjustmentArgsDto
  ): Promise<ClaimAdjustment[]> {
    const result = await this.gateway.getClaimAdjustments(request);
    return result.map(dto => new ClaimAdjustment(this.root, dto));
  }

  public async addClaimAdjustment(
    addClaimAdjustment: AddClaimAdjustmentDto
  ): Promise<ClaimAdjustment> {
    const claimAdjustmentDto =
      await this.gateway.addClaimAdjustment(addClaimAdjustment);

    await this.updateClaimsMapWithAdjustment(claimAdjustmentDto);

    return new ClaimAdjustment(this.root, claimAdjustmentDto);
  }

  public getAvailableProviderTypes(
    contractTypes: string[] | undefined
  ): KeyTextValue[] {
    const accProviderTypes =
      this.root.practice.ref.accProviderTypes.keyTextValues;

    const providerContractTypes = this.root.practice.ref
      .accProviderContractTypes.values as ProviderAccContractTypeDto[];

    return filterAvailableAccProviderTypes(
      accProviderTypes,
      providerContractTypes,
      contractTypes
    );
  }

  /**
   * updates the list of claimAdjustments a claim has with the new or modfied claimAdjustment
   * @param claimAdjustmentDto
   */
  public async updateClaimsMapWithAdjustment(
    claimAdjustmentDto: ClaimAdjustmentDto
  ): Promise<ClaimAdjustment> {
    const claimToUpdate = this.claimsMap.get(claimAdjustmentDto.claimId);

    if (claimToUpdate) {
      claimToUpdate.mergeClaimAdjustment(claimAdjustmentDto);
      return claimToUpdate.claimAdjustments.find(
        x => x.id === claimAdjustmentDto.id
      )!;
    } else {
      const claim = await this.getClaim(claimAdjustmentDto.claimId);
      return claim.claimAdjustments.find(x => x.id === claimAdjustmentDto.id)!;
    }
  }

  public async patchClaimAdjustment(
    claimAdjustmentDto: PatchClaimAdjustmentDto
  ): Promise<ClaimAdjustment> {
    const { id, eTag, ...rest } = claimAdjustmentDto;

    const patchValues = nullifyAnyUndefined(rest);

    const updatedClaimAdjustment = await this.gateway.patchClaimAdjustment({
      id,
      eTag,
      ...patchValues
    });

    return await this.updateClaimsMapWithAdjustment(updatedClaimAdjustment);
  }

  public async deleteClaimAdjustment(id: string): Promise<void> {
    const claimAdjustment = await this.getClaimAdjustment(id);
    const claim = await this.getClaim(claimAdjustment.claimId);
    await this.gateway.deleteClaimAdjustment(id);
    claim.deleteClaimAdjustmentFromMap(id);
  }

  public async fetchClaims(
    filter: ClaimsFilter & PagingOptions
  ): Promise<QueryResult<Claim>> {
    const response = await this.gateway.getClaims(filter);
    const { results, ...rest } = response;

    const claims = results.map(this.mergeClaim);

    claims.map(x => x.populateClaimAdjustmentMap());
    return { results: claims, ...rest };
  }

  public async getClaimByCalendarEventId(
    id: string
  ): Promise<Claim | undefined> {
    if (this.root.core.tenantDetails!.country === Country.NewZealand) {
      const claimAppointments = await this.root.acc.getClaimAppointmentDtos({
        calendarEventId: id
      });

      if (!!claimAppointments[0]) {
        return this.root.acc.getClaim(claimAppointments[0].claimId);
      }
    }
    return undefined;
  }

  public async getClaimEncounters(claimId: string, patientId: string) {
    // Get all encounter Ids by the claim ID
    const encounterIds = await this.getEncounterIdsByClaimId(claimId);

    const encounters = await this.root.clinical.getEncounters({
      patientId,
      encounterIds
    });

    return encounters;
  }

  public async getEncounterIdsByClaimId(claimId: string): Promise<string[]> {
    return await this.gateway.getEncounterIdsByClaimId(claimId);
  }

  public async addClaimEncounter(
    request: CreateClaimEncounterDto
  ): Promise<ClaimEncounterDto> {
    return await this.gateway.addClaimEncounter(request);
  }

  public async deleteClaimEncounter(
    claimId: string,
    encounterId: string
  ): Promise<void> {
    await this.gateway.deleteClaimEncounter(claimId, encounterId);
  }

  public async deleteCheck(claimId: string): Promise<DeleteCheckDto> {
    return await this.gateway.deleteCheck(claimId);
  }

  public async lodgeClaimAdjustment(claimAdjustmentId: string) {
    await this.gateway.lodgeClaimAdjustment(claimAdjustmentId);
  }

  public async addClaimReviewDocumentRender(
    documentId: string,
    encounterId: string
  ) {
    await this.gateway.addClaimReviewDocumentRender(documentId, encounterId);
  }

  public async getClaimEpisodesOfCare(
    request: ClaimEpisodeOfCareArgsDto
  ): Promise<ClaimEpisodeOfCareDto[]> {
    return await this.gateway.getClaimEpisodesOfCare(request);
  }

  public async deleteClaimEpisodesOfCare(
    request: DeleteClaimEpisodeOfCareArgs
  ): Promise<void> {
    await this.gateway.deleteClaimEpisodesOfCare(request);
  }

  public async updateClaimEpisodeOfCare(
    request: ClaimEpisodeOfCareDto
  ): Promise<ClaimEpisodeOfCareDto> {
    return await this.gateway.updateClaimEpisodeOfCare(request);
  }

  public async getEpisodeOfCareScopedClinicalDataByClaimId(
    claimId: string,
    patientId: string
  ) {
    const claimEpisodesOfCare = await this.getClaimEpisodesOfCare({ claimId });

    if (!!claimEpisodesOfCare.length) {
      return this.root.clinical.getEpisodeOfCareScopedClinicalData({
        patientId,
        episodeOfCareId: claimEpisodesOfCare[0].episodeOfCareId
      });
    }

    return undefined;
  }

  public async getTreatmentPlanByClaimId(
    claimId: string,
    patientId: string,
    businessRoleCode: string | undefined
  ) {
    const claimEpisodesOfCare = await this.getClaimEpisodesOfCare({ claimId });

    if (!!claimEpisodesOfCare.length) {
      const eocId = claimEpisodesOfCare[0].episodeOfCareId;
      const clinicalData =
        await this.root.clinical.getPatientScopedClinicalData(patientId);
      if (clinicalData && clinicalData.patientTreatmentPlan) {
        let treatmentPlan: PatientTreatmentPlanDataItemDto | undefined;
        if (
          this.root.core.hasPermissions(Permission.MultiProviderClaimsAllowed)
        ) {
          treatmentPlan =
            clinicalData.patientTreatmentPlan.treatmentPlans?.find(
              x => x.linkId === eocId && x.businessRoleCode === businessRoleCode
            );
        } else {
          treatmentPlan =
            clinicalData.patientTreatmentPlan.treatmentPlans?.find(
              x => x.linkId === eocId
            );
        }
        return treatmentPlan;
      }
      return undefined;
    }

    return undefined;
  }

  public async addClaimEpisodeOfCare(
    claimId: string,
    episodeOfCareId: string
  ): Promise<ClaimEpisodeOfCareDto> {
    return await this.gateway.addClaimEpisodeOfCare({
      claimId,
      episodeOfCareId
    });
  }

  public async getClaimReview(id: string): Promise<ClaimReviewDto> {
    const claimReviewDto = await this.gateway.getClaimReview(id);
    return new ClaimReview(claimReviewDto);
  }

  public async getClaimReviewByClaimId(
    claimId: string
  ): Promise<ClaimReview | undefined> {
    const reviews = await this.gateway.getClaimReviews({ claimId });
    if (!!reviews.length) {
      return new ClaimReview(reviews[0]);
    }
    return undefined;
  }

  @action
  private mergePurchaseOrder = (dto: PurchaseOrderDto) => {
    return mergeModel({
      dto,
      getNewModel: () => new PurchaseOrder(this.root, dto),
      map: this.purchaseOrderMap
    });
  };

  @sharePendingPromise()
  public async getPurchaseOrder(
    id: string,
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ): Promise<PurchaseOrder> {
    if (!options.ignoreCache) {
      const purchaseOrder = this.purchaseOrderMap.get(id);
      if (purchaseOrder) {
        return purchaseOrder;
      }
    }

    const purchaseOrderDto = await this.gateway.getPurchaseOrder(id);
    const purchaseOrder = this.mergePurchaseOrder(purchaseOrderDto);

    return purchaseOrder;
  }

  public async getPurchaseOrdersByClaimId(
    claimId: string
  ): Promise<PurchaseOrder[]> {
    const purchaseOrders = await this.gateway.getPurchaseOrders({ claimId });

    if (purchaseOrders.length > 0) {
      return purchaseOrders.map(this.mergePurchaseOrder);
    }
    return [];
  }

  public async addPurchaseOrder(
    purchaseOrder: AddPurchaseOrderDto
  ): Promise<PurchaseOrder> {
    const purchaseOrderDto = await this.gateway.addPurchaseOrder(purchaseOrder);
    return this.mergePurchaseOrder(purchaseOrderDto);
  }

  public async updatePurchaseOrder(
    purchaseOrderDto: PurchaseOrderDto
  ): Promise<PurchaseOrder> {
    const purchaseOrder = await this.gateway.updatePurchaseOrder({
      ...purchaseOrderDto,
      id: purchaseOrderDto.id,
      eTag: purchaseOrderDto.eTag
    });
    return this.mergePurchaseOrder(purchaseOrder);
  }

  public async deletePurchaseOrder(id: string): Promise<void> {
    await this.gateway.deletePurchaseOrder(id);
  }

  public async purchaseOrderDeleteCheck(id: string): Promise<boolean> {
    return await this.gateway.purchaseOrderDeleteCheck(id);
  }

  public async addClaimReview(
    claimReview: AddClaimReviewDto
  ): Promise<ClaimReviewDto> {
    const claimReviewDto = await this.gateway.addClaimReview(claimReview);
    return new ClaimReview(claimReviewDto);
  }

  public async patchClaimReview(
    request: PatchClaimReviewDto
  ): Promise<ClaimReviewDto> {
    const nullifiedClaimReview = nullifyAnyUndefined(request);
    const claimReviewDto = await this.gateway.patchClaimReview({
      ...nullifiedClaimReview,
      id: request.id,
      eTag: request.eTag
    });
    return new ClaimReview(claimReviewDto);
  }

  public async deleteClaimReview(id: string): Promise<void> {
    await this.gateway.deleteClaimReview(id);
  }

  public async addClaimFormInstance(request: ClaimFormInstanceDTO) {
    try {
      return await this.gateway.addClaimFormInstance(request);
    } catch (error) {
      this.notification.error(error, {
        messageOverride:
          "An error occurred linking the claim with the deployed form."
      });
      throw error;
    }
  }

  public async getBillableOrganisations(): Promise<BillableOrganisationDto[]> {
    return this.gateway.getBillableOrganisations();
  }

  public async fetchAccTerminologies(
    search: GetAccTerminologiesArgsDto
  ): Promise<QueryResult<AccTerminologyDto>> {
    const response = await this.gateway.getAccTerminologies(search);
    const { results, ...rest } = response;

    results.forEach(this.mergeAccTerminologyMap);

    return { results, ...rest };
  }

  public getAccTerminologyFromMap = (
    key: string
  ): AccTerminologyDto | undefined => this.accTerminologyMap.get(key);

  @action
  public mergeAccTerminologyMap = (terminology: AccTerminologyDto) => {
    return (
      !this.accTerminologyMap.get(terminology.diagnosisKey) &&
      this.accTerminologyMap.set(terminology.diagnosisKey, terminology)
    );
  };

  public async transitionClaimEpisodeOfCareToPrivate(
    claimId: string,
    episodeOfCareId: string
  ) {
    // Transition to private
    const claimEpisodesOfCare = await this.getClaimEpisodesOfCare({
      claimId,
      episodesOfCare: [episodeOfCareId]
    });

    // Unlink the claim from the EOC by deactivating
    if (claimEpisodesOfCare.length) {
      const claimEOC = { ...claimEpisodesOfCare[0], isActive: false };

      const [claimAppts] = await Promise.all([
        // Get all claim appointments
        this.getClaimAppointmentDtos({ claimIds: [claimEOC.claimId] }),
        // Update the Claim-EpisodeOfCare
        this.updateClaimEpisodeOfCare(claimEOC)
      ]);

      // Unlink claim-appointments if exist
      await Promise.all(
        claimAppts.map(m => this.deleteClaimAppointmentDto(m.id))
      );
    }
  }

  public async queueCheckClaimStatus(claimId: string): Promise<void> {
    await this.gateway.queueCheckClaimStatus(claimId);
  }

  public async getSchedules(request: GetSchedulesArgsDto): Promise<Schedule[]> {
    const result = await this.gateway.getSchedules(request);
    return result.map(dto => new Schedule(this.root, dto));
  }
}
